<template>
  <div>
    <div class="row">
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-start">
              <img src="/img/logo/Logo-UNJ.png" alt="" height="100" />
            </div>

            <div class="d-flex justify-content-center">
              <h4>Sistem Administrasi Umum</h4>
            </div>
            <div class="d-flex justify-content-center">
              <h4>
                Program Studi Fisika, Pendidikan Fisika, dan Magister Pendidikan
                Fisika
              </h4>
            </div>
            <hr style="height: 1px; background: black" />

            <!-- <ul class="undot">
              <li>Nama Mahasiswa : {{ detail.student_name }}</li>
              <li>NIM : {{ detail.registration_number }}</li>
              <li>Semester ke- : {{ detail.semester_name }}</li>
              <li>Tahun ke- : {{ detail.year_id }}</li>
              <li>Program Studi : {{ detail.study_program_name }}</li>
              <li>Fakultas : {{ detail.faculty_name }}</li>
              <li>Alamat : {{ detail.address }}</li>
              <li>No. Telp/Wa : {{ detail.phone }}</li>
              <li>Alamat Email : {{ detail.email }}</li>
              <li>Judul/Topik Penelitian : {{ detail.title }}</li>
              <li>Dosen Pembimbing 1 : {{ detail.teacher_mentor1_name }}</li>
              <li>Dosen Pembimbing 2 : {{ detail.teacher_mentor2_name }}</li>
              <li>Nama Laboratorium : {{ detail.lab_name }}</li>
              <li>Alamat Laboratorium : {{ detail.lab_address }}</li>
              <li>Jenis Pengujian : {{ detail.test_type }}</li>
              <li>Nama Sampel yang diuji : {{ detail.sample_name }}</li>
              <li>Jumlah Sampel : {{ detail.sample_amount }}</li>
              <li>Tujuan Pengujian : {{ detail.test_purpose }}</li>
              <li>Keterangan : {{ detail.description }}</li>
            </ul> -->

            <table class="table table-borderless">
              <tr style="line-height: 1">
                <td>Nama Mahasiswa</td>
                <td>:</td>
                <td>{{ detail.student_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>NIM</td>
                <td>:</td>
                <td>{{ detail.registration_number }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Semester ke-</td>
                <td>:</td>
                <td>{{ detail.semester_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Tahun ke-</td>
                <td>:</td>
                <td>{{ detail.year_id }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Program Studi</td>
                <td>:</td>
                <td>{{ detail.study_program_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Fakultas</td>
                <td>:</td>
                <td>{{ detail.faculty_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat</td>
                <td>:</td>
                <td>{{ detail.address }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>No. Telp/Wa</td>
                <td>:</td>
                <td>{{ detail.phone }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat Email</td>
                <td>:</td>
                <td>{{ detail.email }}</td>
              </tr>
              <!--  -->
              <tr style="line-height: 1">
                <td>Jenis Surat</td>
                <td>:</td>
                <td>Surat Permohonan Uji Sampel Penelitian di Luar Prodi</td>
              </tr>
              <tr style="line-height: 1">
                <td>Judul/Topik Penelitian</td>
                <td>:</td>
                <td>{{ detail.title }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Dosen Pembimbing 1</td>
                <td>:</td>
                <td>{{ detail.teacher_mentor1_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Dosen Pembimbing 2</td>
                <td>:</td>
                <td>{{ detail.teacher_mentor2_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Nama Laboratorium</td>
                <td>:</td>
                <td>{{ detail.lab_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat Laboratorium</td>
                <td>:</td>
                <td>{{ detail.lab_address }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Jenis Pengujian</td>
                <td>:</td>
                <td>{{ detail.test_type }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Nama Sampel yang diuji</td>
                <td>:</td>
                <td>{{ detail.sample_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Jumlah Sampel</td>
                <td>:</td>
                <td>{{ detail.sample_amount }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Tujuan Pengujian</td>
                <td>:</td>
                <td>{{ detail.test_purpose }}</td>
              </tr>
              <!--  -->
              <tr style="line-height: 1">
                <td>Keterangan</td>
                <td>:</td>
                <td>{{ detail.description }}</td>
              </tr>
            </table>

            <!-- <div class="d-flex justify-content-start mt-2">
              <div
                class="
                  d-flex
                  justify-content-start
                  align-items-center
                  image-container
                "
              >
                <div class="image-input-wrapper" style="position: relative">
                  <img class="image" :src="detail.sign" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <b-button
          variant="danger"
          v-b-tooltip.hover
          title="Cetak PDF"
          @click="downloadPDF"
        >
          <i class="fas fa-file-pdf px-0"></i>
        </b-button>
      </div>
    </div>
    <b-button
      @click="$router.push('/letters/sample-research-outside/list')"
      variant="secondary"
      class="mt-3"
      >Tutup</b-button
    >

    <!-- Layout PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="500"
      filename="Surat Permohonan Uji Sampel Penelitian di Luar Prodi"
      :pdf-quality="1"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="950px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <DetailPdf :detail="detail" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import DetailPdf from "@/view/pages/letters/sample-research-outside/DetailPdf.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    DetailPdf,
    VueHtml2pdf,
  },
  data() {
    return {
      detail: {},
    };
  },
  methods: {
    async get() {
      this.detail = await module.get(
        "api/sample-research-outside-forms/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.$router.push("/letters/sample-research-outside/list");
      }
    },
    downloadPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Surat Permohonan Uji Sampel Penelitian di Luar Prodi",
        route: "/sample-research-outside",
      },
      { title: "Detail" },
    ]);

    this.get();
  },
};
</script>

<style scoped>
.undot li {
  list-style-type: none;
}

.image-input-wrapper {
  width: 130px !important;
  height: 130px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>